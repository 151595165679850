<template>
  <div>
    
    <CRow>
      <CCol md="12" lg="12">
        <CCard>
          <CCardHeader>
            一般ユーザーのダッシュボード
            <CSwitch
              class="float-right"
              size="sm"
              shape="pill"
              color="info"
              data-on="On"
              data-off="Off"
              :checked="true"
            />
          </CCardHeader>
          <CCardBody>
            一般ユーザーのステータス・予約一覧・アカウント情報を示すこと。
            <div class="bd-example">
              <li class="h6">現在有効の予約を示すこと</li>
              <li class="h6">現在有効の空き部屋を待ちすることを示すこと</li>
              <li class="h6">獲得済みクーポンを示すこと</li>
              <li class="h6">その他</li>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <!-- TODO: -->
  </div>
</template>

<script>

export default {
  name: "Dashboard",
  data() {
    return {
      loading: false,
    };
  },
  computed: {

  },
  methods: {

  },
  beforeMount() {
  },
};
</script>
